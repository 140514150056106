<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row justify-content-around">
        <div class="col">
          <h5 class="page-header">Manage tags</h5>
        </div>
        <div class="col text-end">
          <BaseButton class="btn-secondary" @click="$apollo.queries.tags.refetch()">Refresh</BaseButton>
          <BaseButton v-if="user.isAdminUser" class="ms-3 btn-success" @click="createNewTag()"> New Tag </BaseButton>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <div class="row pt-4">
        <div class="col">
          <!-- Loading spinner -->
          <div
            v-if="$apollo.loading"
            class="position-fixed end-0 w-100 justify-content-center"
            style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
          >
            <div class="text-center col pt-5 mt-5">
              <BaseSpinner />
              <p class="d-block lead fs-4 mt-5">Loading tags</p>
            </div>
          </div>
          <div v-else>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :sort-options="{ enabled: true }"
              :pagination-options="paginationOptions"
              style-class="vgt-table condensed striped"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Custom Actions section -->
                <div
                  v-if="props.column.field == 'after'"
                  class="capitalize-first text-xl w-100 flex text-end"
                  :title="props.row.id"
                >
                  <BaseLink
                    v-if="user.isAdminUser"
                    class="inline text-primary fs-5 cursor-pointer mr-3"
                    title="Edit Tag"
                    :classes="'px-2'"
                    :to="{ name: 'tags.edit', params: { id: props.row.id } }"
                  >
                    <BaseIcon :name="['far', 'edit']" />
                  </BaseLink>
                  <BaseButton
                    v-if="user.isAdminUser && !props.row.hiddenInActivities"
                    class="inline text-primary fs-5 cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Deactivate (make unavailable for new activities)"
                    @click="deactivate(props.row.id)"
                  >
                    <BaseIcon :name="['far', 'times-circle']" />
                  </BaseButton>
                  <BaseButton
                    v-else-if="user.isAdminUser"
                    class="inline text-primary fs-5 cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Activate (make available for new activities)"
                    @click="deactivate(props.row.id, false)"
                  >
                    <BaseIcon :name="['far', 'check-circle']" />
                  </BaseButton>
                  <!--<BaseButton
                    v-if="user.isAdminUser && !props.row.hiddenInFilters"
                    class="inline text-primary fs-5 cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Hide tag in filters"
                    @click="hideFromFilter(props.row.id)"
                  >
                    <BaseIcon :name="['far', 'eye-slash']" />
                  </BaseButton>
                  <BaseButton
                    v-else-if="user.isAdminUser"
                    class="inline text-primary fs-5 cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Show tag in filters"
                    @click="hideFromFilter(props.row.id, false)"
                  >
                    <BaseIcon :name="['far', 'eye']" />
                  </BaseButton>-->
                </div>
                <div v-else-if="props.column.field == 'tagColor'" class="row ms-2">
                  <span
                    style="'height: 25px; width: 25px;'"
                    :style="{ background: props.row.tagColor }"
                    class="col-4 col-form-label"
                  ></span>
                  <span class="col-8 ms-2 fw-light">{{ props.row.tagColor }}</span>
                </div>

                <!-- standard column -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf } from "@gql/user";
import { GetTags, showHideTagFromNewActivities, showHideTagFromFilters } from "@gql/tag";

import { format, parseISO } from "date-fns";
import { uniq } from "lodash";

export default {
  page: {
    title: "Tags",
    meta: [{ name: "description", content: "Users" }],
  },
  components: { Layout },
  data() {
    return {
      loading: true,
      showHidden: false,
      columns: [
        {
          label: "Tag",
          field: "label",
          filterOptions: {
            // styleClass: '', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: "Tag name", // placeholder for filter input
            // filterValue: 'Test', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, // custom filter function that takes two vars: data, filterString: should return true if data matches the filterString, otherwise false
            // trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: "Tag Type",
          field: "tagTypeName",
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.tagTypes,
          },
        },
        {
          label: "Tag Categories",
          field: "tagCategoryNames",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Colour",
          field: "tagColor",
          filterOptions: {
            enabled: true,
            placeholder: "Color string",
          },
        },
        {
          label: "Created by",
          field: "createdByName",
          filterOptions: {
            enabled: true,
            placeholder: "Creator's name",
          },
        },
        {
          label: "Created",
          field: "createdAtFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
          filterOptions: {
            enabled: true,
            placeholder: "Created Date",
          },
        },
        {
          label: "",
          field: "after",
        },
      ],
      paginationOptions: {
        enabled: true,
        perPage: 25,
        perPageDropdown: [10, 25, 50, 100],
      },
    };
  },
  apollo: {
    user: LocalGetSelf,
    tags: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: { id: { equals: this.user.company.id } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update: (data) => {
        return data.tags.map((tag) => ({
          ...tag,
          tagTypeName: tag.type.label,
          tagCategoryNames: tag.categories.map((category) => category.label).join(", "),
          createdAtFormatted: format(parseISO(tag.createdAt), "yyyy-MM-dd"),
          createdByName: tag.createdBy.name,
          tagColor: tag.color ? tag.color : tag.type.color,
        }));
      },
      error: (err) => this.$log.error(err),
      fetchPolicy: "cache-and-network",
    },
  },
  computed: {
    rows: function () {
      if (!this.tags) return [];
      return this.tags;
    },
    tagTypes: function () {
      if (!this.tags) return [];
      return uniq(this.tags.map((tag) => tag.type.label));
    },
  },
  methods: {
    refreshTable: function () {
      this.loading = true;
      this.$apollo.queries.tags.refresh();
    },
    tableOnEditItem: function (tagId) {
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("tableOnEditItem - going to tags.edit", { id: tagId });
        this.$router.push({ name: "tags.edit", params: { id: tagId } });
      }
    },
    createNewTag: function () {
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("createNewTag - going to tags.new");
        this.$router.push({ name: "tags.new" });
      }
    },
    deactivate: function (tagId, hide = true) {
      if (this.user && this.user.isAdminUser) {
        this.$apollo
          .mutate({
            mutation: showHideTagFromNewActivities,
            variables: {
              id: tagId,
              hiddenInNewActivities: hide,
            },
            // Update the cache with the result
            update: (store, { data: { updateTag } }) => {},
            error(err) {
              this.$log.error("hideTag - API Error", err);
            },
          })
          .then((data) => {})
          .catch((err) => {
            this.$log.error("hideTag - Apollo Error", err);
            this.loading = false;
          });
      }
    },
    hideFromFilter: function (tagId, hide = true) {
      if (this.user && this.user.isAdminUser) {
        this.$apollo
          .mutate({
            mutation: showHideTagFromFilters,
            variables: {
              id: tagId,
              hiddenInFilters: hide,
            },
            // Update the cache with the result
            update: (store, { data: { updateTag } }) => {},
            error(err) {
              this.$log.error("hideTag - API Error", err);
            },
          })
          .then((data) => {})
          .catch((err) => {
            this.$log.error("hideTag - Apollo Error", err);
            this.loading = false;
          });
      }
    },
  },
};
</script>
